import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "buttons"
    }}>{`Buttons`}</h1>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`<a href="#" class="btn">Button Text</a>`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`<button class="button-default large">Big Fat Button</button>`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`Margins`}</p>
    <p>{`Padding (top/bottom)`}</p>
    <p>{`Underline`}</p>
    <p>{`Use of arrow/caret/icons`}</p>
    <p>{`Background Color`}</p>
    <p>{`Border radius`}</p>
    <p>{`Use of gradient`}</p>
    <p>{`Contrast with background`}</p>
    <p>{`Capitalization`}</p>
    <p>{`Labeling Approach`}</p>
    <p>{`Mobile views`}</p>
    <p>{`Variation within a site`}</p>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      